// Subscription Plan
.subscription-plan-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px;
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #dddcdc;
	padding: 8px 16px;
	margin: 0px 8px;

	.subscription-title {
		font-size: 1.5rem;
		font-weight: 700;
		color: #050001;
		border-bottom: 1px solid rgba(221, 220, 220, 0.32);
		padding: 8px 16px;
	}

	.subscriptionPlan-text {
		color: #0a3352;
		font-size: 1rem;
		font-weight: 700;
		border-bottom: 1px solid rgba(221, 220, 220, 0.32);
		padding: 8px 16px;
	}

	.filter-plan-wrapper {
		display: flex;
		justify-content: space-between;
		background: #f7f7f7;
		padding: 8px 16px;

		.filter-plan-item {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			flex: 1;

			.filter-filed {
				width: 33.333%;

				.control-field__label {
					color: #707170 !important;
					font-weight: 500 !important;
					line-height: 22.4px !important;
				}

				.control-field__body .date-icon svg path {
					stroke: #d4a655 !important;
				}
			}

			.btn-filter {
				width: 114px;
				height: 48px;
				padding: 0px 16px;
				font-size: 18px;
				font-weight: 700;
			}

			.btn-apply {
				color: #fff;
				background: #d4a655;
			}

			.btn-clear {
				background: #ffffff;
				border: 1px solid #707170;
				color: #707170;
			}
		}

		.filter-plan-item:last-child {
			justify-content: end;
		}
	}
	.pay-subscription-plan-btn {
		padding: 8px 16px;
		border: 1px solid transparent;
		border-radius: 6px;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		text-transform: capitalize;
		background-color: #d4a655;
		color: #fff;

		&:hover {
			background-color: #fff;
			border: 1px solid #d4a655;
			color: #d4a655;
		}
	}
	.table-bordered td {
		vertical-align: middle;
		color: #707170 !important;
		font-weight: 600 !important;
		font-size: 1rem !important;
		line-height: 30px !important;
	}
}

.thead-bg {
	background-color: #f7f6f6;
	th {
		font-size: 1rem !important;
		font-weight: 700 !important;
		color: #0a3352 !important;
		line-height: 22px !important;
	}

	th:last-child {
		text-align: center !important;
	}
}

.table-bordered th,
.table-bordered td {
	border: none;
}

// .table-bordered th,
// .table-bordered td {
// 	padding: 20px;
// }

.table-bordered tbody tr {
	border-bottom: 1px solid #dddcdc;
}

.payment-status-color {
	justify-content: center;
	align-items: center;
	border: 1px solid;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 0;
	max-width: 78px;
}

.payment-status-color-success {
	background: #f2fff8;
	color: #019d4c;
	border: 1px solid #019d4c;
}

.payment-status-color-free {
	background: #f5f5f5;
	color: #707170;
	border: 1px solid #707170;
}

.payment-status-color-expired {
	background: #fff9f9;
	color: #ea5455;
	border: 1px solid #ea5455;
}

.payment-status-color-upgraded {
	background: #ebf4ff;
	color: #1e85ff;
	border: 1px solid #1e85ff;
}

.payment-status-color-cancelled {
	background: #fff9f9;
	color: #ea5455;
	border: 1px solid #ea5455;
}

.payment-status-color-pending {
	background: #fffbf6;
	color: #ff9f43;
	border: 1px solid #ff9f43;
}

.payment-status-color-download {
	background: #fff;
	color: #d4a655;
	border: 1px solid #d4a655;
	padding: 7px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.column-flex {
	display: flex;
	align-items: center;
	justify-content: end;
	.payment-status-color {
		svg {
			path {
				stroke: #d4a655;
			}
		}
	}
}

.btn-payNow {
	border-radius: 6px;
	background: #d4a655;
	color: #fff;
	width: 75px;
	height: 40px;
	display: flex;
	justify-content: center;
	white-space: nowrap;
}

.info-icon-end {
	text-align: end !important;
}

.sp-title {
	text-align: center;
	margin: 10px 0px;
	padding: 16px 0px;
}
.subscription-modal-wrapper {
	display: flex;
    flex-direction: column;
    /* grid-gap: 1rem; */
    gap: 10px;
    border-radius: 12px;
    padding: 10px;
	.subscriptionModal-title {
		font-size: 18px;
		font-weight: 600;
		color: #050001;
		border-bottom: 1px solid rgba(221, 220, 220, 0.32);
		padding: 20px 0px;
	}

	.sub-package-item {
		display: flex;
		align-items: center;
		gap: 10px;

		.subscriptionModal-text {
			font-size: 18px;
			font-weight: 600;
			color: #707170;
		}

		.subscriptionModal-price {
			width: 175px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			background: rgba(212, 166, 85, 0.16);
			border: 1px solid #d4a655;
		}
		.subscriptionModal-currency {
			font-size: 18px;
			font-weight: 400;
			color: #2c302e;
		}
	}

	.custom-padding {
		padding-left: 30px;
	}
}

.custom-border {
	border-bottom: 1px solid rgba(221, 220, 220, 0.32);
	padding: 5px;
}

.custom-border:last-child {
	border-bottom: none;
}

// .modal-header h5 {
// 	font-size: 18px;
// 	font-weight: 400;
// 	text-align: center;
// 	width: 100%;
// 	padding: 20px;
// }

.priority-wrapper {
	width: 100%;
	max-width: 280px;
	position: absolute;
	top: 6%;
	z-index: 3;
	background: #fff;
	transform: translateX(-120%);
	box-shadow: 0px 2px 12px 0px #00000014;
	padding: 1rem;
	transition: transform 0.2s ease !important;
	[dir="rtl"] &{
		transform: translateX(110%);

	}
	.priority-items {
		padding: 1rem;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		grid-gap: 1rem;
		gap: 1rem;
		border: 1px solid #f7f6f6;
		box-shadow: 0px 0px 14px 0px #00000005;
		border-radius: 8px;

		.priority-item {
			background-color: #fbfbfa;
			display: flex;
			align-items: center;
			gap: 14px;
			padding: 10px;

			.item-text {
				font-size: 16px;
				font-weight: 400;
			}

			.priority-text-danger {
				color: #ea5455;
			}

			.priority-text-medium {
				color: #d4a655;
			}

			.priority-text-low {
				color: #707170;
			}
		}
	}

	.priority-item-btn {
		margin: 1rem 0;
		.item-btn {
			background-color: #d4a655;
			color: #fff;
			font-weight: 700;
			font-size: 1rem;
			line-height: 30px;
			padding: 0px 32px;
			border-radius: 4px;
		}
	}
}

.crm-priority-modal {
	transform: translate(125%, 20px);

	.modal-header {
		padding: 0 !important;
		width: 90% !important;
		margin: auto !important;

		.modal-header h5 {
			padding: 10px !important;
			text-align: justify !important;
		}
	}

	.close {
		display: none;
	}
}

.popup-translate-20 {
	transform: translateX(0) !important;
	transition: transform 0.2s ease !important;
}

.text-price-free {
	border: 1px solid rgb(1, 157, 76);
	border-radius: 4px;
	padding: 5px 0px 6px 0px;
	text-align: center;
	background-color: rgba(194, 200, 208, 0.3);
	color: rgb(1, 157, 76);
	padding: 3px 50px;
	font-size: 16px;
}

.tooltip .tooltip-inner {
	max-width: 100% !important;
}

// subscription terms and conditions
.subscription-terms-model{
	.modal-header{
		padding: 25px 22px 25px 22px !important;
		.modal-title{
			width: 100%;
			text-align: center;
			font-size: 18px;
    font-weight: 600;
		}
	}
.terms-condition{
	padding: 8px;
	.data-wrapper{
		overflow: auto;
    max-height: 350px;
		&::-webkit-scrollbar {
			width: 4px;               /* width of the entire scrollbar */
		}
		&::-webkit-scrollbar-track {
			background: #dddcdc;   
			border-radius: 20px;      /* color of the tracking area */
		}
		&::-webkit-scrollbar-thumb {
			background-color: #d4a655;    /* color of the scroll thumb */
			border-radius: 20px;       /* roundness of the scroll thumb */
			border: 1px solid #dddcdc;  /* creates padding around scroll thumb */
		}
	}
	.data{
		direction: rtl;
    text-align: right;
		line-height: 1.9;
		padding-right: 21px;
		[dir="left"] &{
			padding-left: 21px;
			direction: ltr;
		}

	}
	.check-box{
		// padding-inline: 25px;
		label{
			color: rgba(234, 84, 85, 1);
			font-size: 16px;
			font-weight: 600;
			cursor: pointer;
		}
	}
	.btn-nxt{
		margin-top: 25px;
		font-size: 16px;
			font-weight: 700;
	}
	input[type=checkbox]{
		width: 20px;
    height: 15px;
	}
}	
.subscription-otp{
	padding: 20px 100px; 
	.text-verify{
		h6{
			font-size: 24px;
			font-weight: 500;
			color: #050001;
			text-align: center;
			margin-bottom: 12px;
		}
		p{
			color: #707170			;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			margin-bottom: 30px;
			span{
				color: #050001;
			}
		}
	}
	.btn-nxt{
		margin-top: 25px;
		font-size: 16px;
			font-weight: 700;
	}
	.resend{
		color: #707170		;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		margin-top: 15px;
		span{
			color: rgba(212, 166, 85, 1);
		}
	}
}
}


.transport-color-download {
	background: #fff;
	color: #d4a655;
	border: 1px solid #d4a655;

}