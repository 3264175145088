.new-header-hotel {
  border-bottom: 1px solid #ccc9c9 !important;

  .main-info {
    display: flex;
    align-items: center;
    gap: 10px;

    h5 {
      font-size: 22px;
      font-weight: 600;
    }

  }

}

.main-new-nav {
  .nav-item {
    .nav-link {
      color: #707170;
      border-bottom: 1px solid #ccc9c9;
      padding: 0.5rem 2rem;

    }

    .active {
      border: 0px;
      color: #eecfa3;
      border-bottom: 2px solid #eecfa3;
    }
  }
}

.filter-rooms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc9c9;
  padding-bottom: 15px;

  .filter-by {
    padding: 8px;
    border: 1px solid #ccc9c9;
    border-radius: 30px;
    color: gray;
  }

  input[type="radio"] {
    accent-color: #b77b11 !important;
    width: 16px;
    height: 16px;
  }

  input[type="radio"]:checked {
    background-color: #D4A655;
    border: 1px solid #D4A655;
    padding: 4px;
  }
}

.room-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 16px;
  .gap-1{
    gap: 14px;
  }
  .type-room {
    display: flex;
    align-items: center;
    gap: 10px;

    .bed-icon {
      background-color: #D4A65533;
      color: #D4A655;
      padding: 5px;
      border-radius: 50%;
    }
  }

  .book-btn {
    background-color: #D4A655;
    color: #ffffff;
    font-size: 16px;
    border: 0;
    padding: 10px 30px;

  }
}