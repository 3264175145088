.trip_operations {
	background-color: white;
	padding: 20px;
	border-radius: 8px;

	button {
		border: none;
		outline: none;
		background-color: transparent;
	}

	.golden-outlined-btn {
		border: 1px solid rgba(212, 166, 85, 1);
		outline: none;
		border-radius: 8px;
		padding: 0.4rem 1rem;
		background-color: transparent;
		color: rgba(212, 166, 85, 1);
		display: flex;
		gap: 0.4rem;
	}

	.disabled-filled-btn {
		border: none;
		outline: none;
		background-color: rgba(247, 246, 246, 1);
		border-radius: 8px;
		padding: 0.4rem 1rem;
		color: rgba(112, 113, 112, 1);
		display: flex;
		gap: 0.4rem;
	}

	.text-gray {
		color: rgba(168, 170, 174, 1);
	}

	.gap-1 {
		gap: 0.5rem;
	}

	.gap-2 {
		gap: 1rem;
	}

	.gap-3 {
		gap: 1.5rem;
	}

	.gap-3 {
		gap: 2rem;
	}

	.gold {
		color: #d4a655;
	}

	.gray {
		color: rgba(112, 113, 112, 1);
	}

	.btn-outline {
		border: 1px solid #d4a655;
		outline: none;
		background-color: transparent;
		color: #d4a655;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 6px;
		border-radius: 4px;

		svg path {
			stroke: #d4a655;
		}
	}

	.divider {
		width: calc(100% - 32px);
		height: 1px;
		background-color: #f7f6f6;
		margin-block: 1.5rem;
		margin-inline: auto;
	}

	.dropdown-menu {
		z-index: 500;
	}

	.header {
		.title {
			font-size: 24px;
			font-weight: bold;
		}

		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2rem;
		flex-wrap: wrap;
		border: none;
	}

	.primary-button {
		background-color: rgba(212, 166, 85, 1);
		color: white;
		border: none;
		font-weight: bold;
		padding: 10px 20px;
		border-radius: 5px;
		cursor: pointer;
	}

	.export-execl-btn {
		outline: none;
		border: 2px solid rgba(33, 150, 83, 1);
		color: rgba(33, 150, 83, 1);
		font-weight: bold;
		background-color: transparent;
		padding: 10px 26px 10px 20px;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		min-width: fit-content;
	}

	.add-btn {
		background-color: rgba(212, 166, 85, 1);
		color: white;
		font-weight: bold;
		border: none;
		padding: 10px 26px 10px 20px;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		min-width: fit-content;
	}

	.filter {
		background-color: rgba(250, 250, 250, 1);
		padding: 20px;
		border-radius: 8px;
		display: flex;
		gap: 1rem;
		margin-top: 2rem;

		.inputs {
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;
			width: 100%;

			>* {
				flex: 1 0 calc(100% / 5);

				@media screen and (max-width: 768px) {
					min-width: 100%;
				}
			}

			input {
				border: none;
				width: 100%;
			}

			.custom-date-picker {
				label {
					margin: 0;
				}

				flex-shrink: 0;

				.inputs-group {
					background-color: white;
					border-radius: 6px;
					border: 1px solid rgba(219, 218, 222, 1);
					padding: 12px;
					height: 44px;
					display: flex;
					flex-wrap: nowrap;
					gap: 1.5rem;
					margin-block: 0.25rem;
					justify-content: space-between;

					input {
						min-width: 60px;
						max-width: 100px;
					}

					@media screen and (max-width: 768px) {
						justify-content: space-around;
					}

					.input {
						max-width: 100px;
						display: flex;
						align-items: center;
					}

					.calendar-icon {
						stroke: black;
					}
				}

				.SingleDatePickerInput {
					background-color: transparent;
				}

				.SingleDatePickerInput_calendarIcon {
					padding: 0;
					margin: 0;
				}

				.DateInput {
					display: flex;
				}
			}
		}

		.actions {
			display: flex;
			gap: 1rem;
			align-items: flex-end;
			margin-bottom: 0.4rem;

			@media screen and (max-width: 768px) {
				flex-wrap: wrap;
			}

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				min-width: fit-content;
			}
		}

		.reset-btn {
			color: rgba(106, 77, 27, 1);

			svg path {
				stroke: rgba(106, 77, 27, 1);
			}

			background-color: rgba(250, 244, 235, 1);
			border: 1px solid rgba(238, 217, 186, 1);
		}
	}

	.table-container {
		overflow: auto;
		margin-top: 2rem;

		table {
			border-collapse: separate;
			border-spacing: 0 10px;
			width: max-content;
			min-width: 100%;

			tbody tr {
				background-color: white;
			}

			tbody td {
				padding: 15px;
				border: 1px solid #ddd;
				vertical-align: top;
			}

			tbody td:not(:last-child) {
				border-right: none;
			}

			tbody td:not(:first-child) {
				border-left: none;
			}

			tbody tr td:first-child {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}

			tbody tr td:last-child {
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			.age-categories {
				display: flex;
				gap: 0.5rem;
				flex-wrap: wrap;
				margin-top: 0.5rem;

				>span {
					display: flex;
					align-items: center;
					gap: 0.3rem;
					background-color: rgba(242, 242, 245, 1);
					border-radius: 8px;
					padding: 1px 6px 1px 2px;
					font-size: 12px;
					color: rgba(112, 113, 112, 1);

					svg {
						width: 16px;
						height: 16px;
					}
				}
			}

			.rating {
				display: flex;
				gap: 0.5rem;
				align-items: center;

				.stars {
					display: flex;
					gap: 0.3rem;

					svg {
						--size: 14px;
						width: var(--size);
						height: var(--size);
					}
				}
			}

			.actions {
				display: flex;
				align-items: center;
				gap: 0.3rem;
				margin-top: 0.5rem;

				button {
					padding: 0;
					background-color: transparent;
					border: none;
					outline: none;
				}
			}
		}
	}

	.operations-dropdown {
		.dropdown-menu {
			padding-block: 12px !important;
			border-radius: 8px !important;
		}

		.dropdown-item {
			display: flex;
			align-items: center;
			gap: 0.8rem;
		}

		.section-title {
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
		}

		.delete-operation-btn {
			svg {
				path {
					stroke: rgb(234, 84, 85);
				}
			}
		}
	}

	.tabs {
		display: flex;
		flex-wrap: nowrap;
		gap: 0;
		overflow-x: auto;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: rgba(112, 113, 112, 1);
		}

		.tab {
			min-width: fit-content;

			button {
				--active-color: rgba(210, 171, 103, 1);
				background-color: transparent;
				border: none;
				outline: none;
				padding: 20px 16px;
				border-bottom: 2px solid rgba(112, 113, 112, 1);
				display: flex;
				align-items: center;
				gap: 1rem;
				position: relative;
				z-index: 2;

				&:active,
				&:focus,
				&.active {
					color: var(--active-color);
					border-color: var(--active-color);

					svg path {
						stroke: var(--active-color);
					}
				}

				&:disabled {
					svg path {
						stroke: rgba(16, 16, 16, 0.3);
					}
				}
			}
		}
	}

	&.addEditView {
		background-color: transparent;
		padding: 0;

		.title {
			font-size: 24px;
			font-weight: bold;
		}

		.add-button {
			margin-top: 1rem;
			border: none;
			outline: none;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			background-color: transparent;
			color: black;
			padding: 0;
			font-weight: 500;

			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;

				border-radius: 6px;
				background-color: #f6ebda;

				svg path {
					stroke: #6a4d1b;
				}
			}
		}

		.header {
			border: none;
			padding: 0;
			background-color: transparent;
			display: flex;
			justify-content: center;

			.table-container {
				// extended from "table-container" class -> direct child of "trip-operation" class
				overflow: auto;
				border-radius: 8px;
				padding-bottom: 1rem;
				width: 100%;

				table {
					border-spacing: 0 10px;

					tbody tr {
						background-color: white;
					}

					tbody td {
						padding: 15px;
						border: none;
						vertical-align: middle;
					}
				}
			}
		}

		.content {
			background-color: white;
			padding: 20px;
			border-radius: 8px;
			margin-top: 2rem;

			.navigation-btn {
				background-color: rgba(33, 150, 83, 1);
				color: white;
				padding: 12px 16px;
				border: none;
				outline: none;
				display: flex;
				align-items: center;
				justify-content: center;

				gap: 0.5rem;

				svg path {
					stroke: white;
				}

				&:disabled {
					background-color: rgba(221, 220, 220, 0.2);
					color: rgba(112, 113, 112, 0.2);

					svg path {
						stroke: rgba(112, 113, 112, 0.2);
					}
				}
			}

			.next-btn {
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			.back-btn {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}

			.details-form-wrapper {
				.details-form {
					display: flex;
					column-gap: 1rem;
					row-gap: 2rem;
					flex-wrap: wrap;

					>div {
						min-width: 130px;
						flex: 1;
					}

					.phone {
						min-width: 300px;
						flex: 1;

						@media screen and (max-width: 768px) {
							min-width: 100%;
						}
					}

					.phone_field_group {
						display: flex;
						flex-wrap: nowrap;
						gap: 0.5rem;

						.flag {
							min-width: 80px;
						}

						.no-flag {
							min-width: 120px;
						}

						.phone_number {
							width: 100%;
						}
					}
				}
			}

			.arrival_departure-form-wrapper {
				.arrival_departure-form {
					.transport-type-field {
						max-width: 400px;
					}
				}

				.title {
					font-size: 20px;
					font-weight: 500;
					color: rgba(210, 171, 103, 1);
					margin-block: 1.5rem;
				}

				.inputs-wrapper {
					display: flex;
					flex-wrap: wrap;
					gap: 1rem;

					>div {
						min-width: 155px;
						flex: 1;
					}
				}

				.deprature-list {
					display: flex;
					flex-direction: column;
					gap: 1.5rem;
				}

				.add-departure-btn {
					margin-top: 1rem;
					border: none;
					outline: none;
					display: flex;
					align-items: center;
					gap: 0.5rem;
					background-color: transparent;
					color: black;
					padding: 0;
					font-weight: 500;

					.icon {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;

						border-radius: 6px;
						background-color: #f6ebda;

						svg path {
							stroke: #6a4d1b;
						}
					}
				}
			}

			.hotel-form-wrapper {
				.hotel-form {
					.city:not(:last-child) {
						margin-bottom: 1rem;
					}

					.city-title {
						color: rgba(210, 171, 103, 1);
						font-size: 20px;
						margin-bottom: 1rem;
						font-weight: 400;
					}

					.city-top-fields {
						display: flex;
						gap: 0.5rem;
						margin-bottom: 1rem;
						align-items: flex-start;

						.city-select-field {
							max-width: 300px;
							width: 100%;
						}

						.buttons-group {
							display: flex;
							gap: 0.5rem;
							margin-bottom: 0.25rem;
							padding-top: 28px;
							align-items: center;

							.add-button {
								margin-top: 0;
								height: auto;
								align-self: stretch;

								.icon {
									height: 44px;
								}
							}

							.delete-button {
								border: none;
								outline: none;
								background-color: transparent;
								color: hsl(0, 100%, 64%);
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 0.5rem;
								padding: 0;

								.icon {
									display: flex;
									align-items: center;
									justify-content: center;

									svg {
										width: 18px;
										height: 18px;

										path {
											stroke: hsl(0, 100%, 64%);
										}
									}
								}
							}
						}
					}

					.city-fields-card {
						background-color: rgb(233 233 233 / 38%);
						padding: 1rem;
						margin-bottom: 1rem;

						display: flex;
						gap: 0.5rem;
						flex-wrap: wrap;

						>div {
							flex: 1;
							min-width: 200px;
						}
					}

					.hotel-fields-wrapper {
						background-color: rgb(233 233 233 / 38%);
						padding: 1rem;
						margin-bottom: 1rem;

						.hotel-select-fields {
							display: flex;
							gap: 0.5rem;
							flex-wrap: wrap;
							margin-bottom: 0.5rem;

							>div {
								flex: 1;
								min-width: 200px;
							}

							.hotel-name-select {
								width: 100%;
								max-width: 400px;
							}

							.hotel-meal-type-select {
								max-width: 200px;
							}
						}

						.counters-wrapper {
							display: flex;
							gap: 0.5rem;
							align-items: center;
							flex-wrap: wrap;

							.counter {
								display: flex;
								gap: 0.5rem;
								justify-content: space-between;
								align-items: center;
								padding: 0.2rem 0.6rem;
								border: 1px solid #ebebeb;
								border-radius: 5px;
								min-width: 200px;
								max-width: 200px;
								background-color: white;
								flex: 1;

								.counter-desc {
									display: flex;
									gap: 0.5rem;

									.icon {
										svg path {
											stroke: #292d32;
										}
									}
								}

								.counter-actions {
									display: flex;
									align-items: center;
									gap: 0.5rem;

									button {
										background-color: transparent;
										border: none;
										outline: none;
										color: #d4a655;
										font-size: 20px;

										&:disabled {
											color: rgba(112, 113, 112, 0.2);
										}
									}
								}
							}
						}
					}
				}
			}

			.attractions-form-wrapper {
				.attractions-form {
					.attractions-inputs-row {
						display: flex;
						gap: 1rem;
						flex-wrap: wrap;

						>div {
							min-width: 200px;
							flex: 1;
						}

						.delete-btn-wrapper {
							min-width: fit-content;
							margin-top: 28px;
							display: flex;
							align-items: center;
							height: 44px;

							button {
								background-color: transparent;
								border: none;
								outline: none;
							}
						}

						&:not(:last-child) {
							margin-bottom: 1rem;
						}
					}
				}
			}

			.transportation-form-wrapper {
				.transportation-form {
					.request-type-select-wrapper {
						max-width: 400px;
						margin-bottom: 1rem;
					}

					.agent-fields {
						display: flex;
						gap: 0.5rem;
						flex-wrap: wrap;
						margin-bottom: 1rem;

						>div {
							flex: 1;
							min-width: 200px;
						}
					}

					.vehicles-card {
						width: 100%;
						width: 100%;
						border: 1.6px solid #e2e2e2;
						border-radius: 8px;

						.title {
							padding: 1rem;
							background-color: rgba(233, 233, 233, 0.8);
							color: rgb(0 0 0 / 40%);
							font-weight: 400;
							font-size: 20px;
						}

						.content {
							padding: 1rem;
							margin-top: 0;

							.vehicle-row {
								display: flex;
								gap: 1rem;
								flex-wrap: wrap;

								&:not(:last-child) {
									margin-bottom: 1rem;
								}

								.viecle-card {
									min-width: 200px;
									max-width: 300px;
									flex: 1;

									label {
										display: flex;
										justify-content: center;
										align-items: center;
										background-color: rgb(203 203 203 / 90%);
										padding: 0.5rem;
										border-radius: 5px;
										font-weight: bold;
									}
								}

								.remove-vehicle {
									button {
										background-color: transparent;
										border: none;
										outline: none;
										height: 40px;
									}
								}
							}
						}
					}
				}
			}
		}

		.public-btns {
			display: flex;
			justify-content: space-between;
			margin-top: 1.5rem;

			.public-close-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				background-color: rgba(203, 203, 203, 0.38);
				color: rgba(112, 113, 112, 1);
				border: none;
				outline: none;
				font-weight: bold;
				padding: 10px 20px;
				border-radius: 5px;
				cursor: pointer;

				svg path {
					stroke: rgba(112, 113, 112, 1);
				}
			}

			.public-save-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				background-color: rgba(212, 166, 85, 1);
				color: white;
				border: none;
				outline: none;
				font-weight: bold;
				padding: 10px 20px;
				border-radius: 5px;
				cursor: pointer;

				svg path {
					stroke: white;
				}
			}
		}
	}

	&.view {
		.title {
			color: rgba(17, 34, 51, 1);
			font-size: 32px;
			font-weight: 700;
		}

		.date {
			margin-top: 0.2rem;
			color: rgba(151, 151, 151, 1);
			font-size: 20px;
		}

		.table-wrapper {
			overflow-x: auto;

			.boxy-table {
				min-width: 100%;
				width: max-content;
				border-collapse: separate;
				font-family: Arial, sans-serif;
				border-radius: 8px;
				border-spacing: 10px;
				border: 1.5px solid #dddcdc;
			}

			.boxy-table th,
			.boxy-table td {
				border-radius: 6px;
			}

			.boxy-table .col-small {
				white-space: nowrap;
			}

			.capitalize {
				text-transform: capitalize;
			}

			.boxy-table .col-large {
				width: 100%;
			}

			.boxy-table thead th {
				background-color: #dddcdc;
				color: #333;
				font-weight: bold;
				padding: 12px;
				text-align: center;
			}

			.boxy-table tbody td {
				background-color: #f7f6f6;
				padding: 10px;
				color: #555;
				border: 1px solid #7071701a;
			}

			.boxy-table tbody tr {
				border-bottom: 1px solid #ddd;
			}

			.boxy-table tbody tr:last-child {
				border-bottom: none;
			}
		}

		.operation-card {
			padding: 20px;
			background-color: white;
			border-radius: 8px;
			border: 1px solid #dddcdc;

			&:not(:last-child) {
				margin-bottom: 1rem;
			}

			.title-wrapper {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				border-bottom: 1px solid rgba(221, 220, 220, 1);
				padding-bottom: 1rem;

				.title {
					color: #d4a655;
					font-size: 24px;
				}

				svg {
					width: 32px;
					height: 32px;

					path {
						stroke: #d4a655;
					}
				}
			}

			.content {
				padding-block: 1rem;
			}

			.cards {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				gap: 1rem;
				border: 2px solid #dddcdc;
				border-radius: 8px;
				padding: 1rem;
				margin-top: 1rem;

				@media screen and (max-width: 768px) {
					grid-template-columns: 1fr;
				}

				.card {
					border: none;
					display: flex;
					flex-direction: column;
					gap: 0.5rem;

					.dark,
					.light {
						color: black;
						border-radius: 4px;
						padding: 12px;
					}

					.dark {
						background-color: #dddcdc;
					}

					.light {
						background-color: #f7f6f6;
					}
				}
			}

			&.details-card {
				margin-top: 1rem;

				.content {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					gap: 1rem;
					row-gap: 2rem;

					@media screen and (max-width: 768px) {
						grid-template-columns: 1fr;
					}

					>div {
						.title {
							color: #707170;
							font-size: 14px;
							font-weight: 400;
							margin-bottom: 0.5rem;
						}

						.desc {
							color: black;
							font-weight: 600;
						}
					}
				}
			}

			&.arrival_departure-card {
				.content {
					.desc-row {
						display: flex;
						flex-wrap: wrap;
						gap: 0.5rem;

						div {
							flex: 1;
							min-width: fit-content;

							>span {
								color: rgba(112, 113, 112, 1);
								font-size: 14px;

								span {
									color: black;
									font-size: 16px;
									margin-left: 0.5rem;
								}
							}
						}
					}
				}
			}

			&.transportation-card {
				.content {
					.desc-row {
						display: flex;
						flex-wrap: wrap;
						gap: 1rem;

						>div {
							flex: 1;
							display: flex;
							flex-direction: column;
							gap: 0.5rem;
							min-width: 200px;

							.title {
								color: rgba(112, 113, 112, 1);
								font-size: 14px;
							}

							.desc {
								color: black;
								font-size: 16px;
							}
						}
					}
				}
			}

			&.passport-card {
				.title-wrapper {
					border: none;
				}

				.content {
					.table-wrapper {
						overflow-x: auto;

						table {
							width: max-content;
							min-width: 100%;
							border-collapse: separate;
							border-spacing: 0;

							th,
							td {
								padding: 14px;
							}

							td {
								padding-block: 10px;
								border-bottom: 1px solid rgba(219, 218, 222, 1);

								&:first-child {
									border-left: 1px solid rgba(219, 218, 222, 1);
								}

								&:last-child {
									border-right: 1px solid rgba(219, 218, 222, 1);
								}
							}

							tbody tr:last-child {
								td:first-child {
									border-bottom-left-radius: 8px;
								}

								td:last-child {
									border-bottom-right-radius: 8px;
								}
							}

							thead tr th {
								background-color: #f7f6f6;
								border-top: 1px solid rgba(219, 218, 222, 1);
								border-bottom: 1px solid rgba(219, 218, 222, 1);

								&:first-child {
									border-top-left-radius: 8px;
									border-left: 1px solid rgba(219, 218, 222, 1);
								}

								&:last-child {
									border-top-right-radius: 8px;
									border-right: 1px solid rgba(219, 218, 222, 1);
								}
							}
						}
					}
				}
			}
		}
	}

	.travelers-wrapper,
	.hotels-wrapper,
	.bus-wrapper {
		background-color: white;
		padding: 16px;
		border-radius: 8px;
	}

	.travelers-wrapper {
		width: 400px;
		height: fit-content;
	}

	.travelers-wrapper {
		align-self: self-start;

		.title {
			margin-bottom: 1.5rem;
			font-weight: 600;
			font-size: 24px;
		}

		.accommodated-travelers {
			display: flex;
			justify-content: space-between;
			color: #707170;
			font-weight: 500;
			margin-bottom: 0.6rem;
		}

		.travelers-list {
			padding-inline: 10px;
			max-height: 700px;
			overflow: auto;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			.travelers-list-item {
				padding: 0.5rem;
				border: 1px solid transparent;
				border-radius: 8px;
				background-color: #fbfbfa;
				cursor: pointer;

				display: flex;
				align-items: center;
				gap: 0.5rem;
				transition: all 0.3s ease-in-out;

				&.active {
					border: 1px solid #d4a655;
					background-color: #faf4eb;
				}
			}

			.traveler-info {
				flex: 1;
				margin-inline-start: 0.3rem;

				div:nth-child(1) {
					line-height: 0.9;
				}
			}
		}
	}

	&.hotel_accommodation {
		&.trip_operations {
			padding: 0;
			border-radius: initial;
			background-color: initial;
		}

		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 1rem;

		.hotels-wrapper {
			--bottom-border-width: 100%;
			flex: 1;
			width: 100%;

			.tabs {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0;
				gap: 1rem;
				overflow-x: auto;
				flex-shrink: 0;

				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: var(--bottom-border-width);
					height: 2px;
					background-color: rgba(112, 113, 112, 1);
				}

				.tabs-list {
					display: flex;
					flex-wrap: nowrap;
					gap: 0;
					min-width: fit-content;

					.tab {
						min-width: fit-content;

						button {
							--active-color: rgba(210, 171, 103, 1);
							background-color: transparent;
							border: none;
							outline: none;
							padding: 20px 16px;
							border-bottom: 2px solid rgba(112, 113, 112, 1);
							display: flex;
							align-items: center;
							gap: 1rem;
							position: relative;
							z-index: 2;

							&:active,
							&:focus,
							&.active {
								color: var(--active-color);
								border-color: var(--active-color);

								svg path {
									stroke: var(--active-color);
								}
							}

							&:disabled {
								svg path {
									stroke: rgba(16, 16, 16, 0.3);
								}
							}
						}
					}
				}

				.tabs-actions {
					display: flex;
					gap: 0.5rem;
					min-width: fit-content;

					button {
						border: 1px solid transparent;
						outline: none;
						font-weight: 700;
						display: flex;
						gap: 0.6rem;
						border-radius: 8px;
						width: fit-content;
						min-width: fit-content;
						padding: 8px 18px 8px;
					}

					.save-btn {
						color: white;
						background-color: #d4a655;

						svg path {
							stroke: white;
						}
					}

					.duplicate-btn {
						border-color: #d4a655;
						color: #d4a655;
						background: transparent;
					}
				}
			}

			.selected-hotels {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 1rem;
				margin-block: 1rem;

				>span {
					font-size: 18px;
					font-weight: 600;
					color: black;
				}

				.hotels-list {
					display: flex;
					gap: 0.5rem;

					.hotels-list-item {
						button {
							outline: none;
							border: none;
							background-color: transparent;
							display: flex;
							gap: 0.6rem;
							padding: 6px 14px;
							border-radius: 4px;
							color: #707170;

							svg path {
								stroke: #707170;
							}
						}
					}

					.hotels-list-item.active {
						button {
							border: 1px solid #f6ebda;
							background-color: #faf4eb;
							color: #6a4d1b;

							svg path {
								stroke: #6a4d1b;
							}
						}
					}
				}
			}

			.hotel-info {
				display: flex;
				gap: 2rem;
				flex-wrap: wrap;

				ul {
					display: flex;
					gap: 0.5rem;
					flex-wrap: wrap;

					li {
						display: flex;
						align-items: center;
						gap: 0.5rem;

						&:not(:last-child) {
							border-right: 1px solid #e9e7e7;
							padding-right: 16px;
						}

						&:not(:first-child) {
							padding-left: 16px;
						}

						div {
							display: flex;
							flex-direction: column;

							span.title {
								color: #707170;
								font-size: 14px;
							}

							span.value {
								color: black;
								font-size: 16px;
								font-weight: 600;
							}
						}
					}
				}

				.add-room-btn {
					border: 1px solid #d4a655;
					outline: none;
					background-color: transparent;
					color: #d4a655;
					display: flex;
					gap: 0.5rem;
					padding: 6px;
					border-radius: 4px;

					svg path {
						stroke: #d4a655;
					}
				}
			}

			.hotel-filter {
				container-type: inline-size;
				background-color: #faf4eb80;
				display: flex;
				flex-wrap: wrap;
				gap: 1rem;
				align-items: end;
				padding: 8px;
				margin-top: 1rem;

				>div {
					flex: 1;
					max-width: 150px;
					min-width: 150px;

					.dropdown {
						width: 100%;
					}

					button {
						width: 100%;
						justify-content: center;
					}

					@container (width < 650px) {
						&.print-btn-wrapper {
							margin-left: 0;
						}
					}
				}

				.re-numbering-btn,
				.print-btn {
					background-color: white;
					height: 44px;
					margin-bottom: 0.25rem;
					font-weight: 700;
					font-size: 16px;
					min-width: fit-content;
				}

				.btn.btn-secondary {
					padding: 0;
					border: none;
					background: transparent;
				}

				.print-btn-wrapper {
					margin-left: auto;
					width: fit-content;
					max-width: fit-content;
					display: flex;
					justify-content: flex-end;
				}
			}

			.rooms-list {
				margin-top: 1rem;
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
				gap: 0.6rem;

				.room-card {
					border: 1px solid #f7f6f6;
					border-radius: 4px;

					.room-card-header {
						background-color: #f7f6f6;
						padding: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 0.5rem;
					}

					.room-type {
						border: 2px solid #f6ebda;
						background-color: #faf4eb;
						border-radius: 24px;
						padding: 4px 10px;
						color: #d4a655;
						flex-shrink: 0;

						svg path {
							stroke: #d4a655;
						}
					}

					.room-number {
						display: flex;
						gap: 0.5rem;
						align-items: center;
					}

					.room-number-edit {
						display: flex;
						gap: 0.5rem;
						align-items: center;
					}

					.room-travelers-list {
						.room-travelers-list-item {
							display: flex;
							align-items: center;
							gap: 0.5rem;
							padding: 16px;

							.remove-icon {
								margin-left: auto;
							}

							>div {
								display: flex;
								flex-direction: column;
							}
						}
					}
				}
			}

			.room-meal-type {
				display: flex;
				align-items: center;
				gap: 1rem;
				padding-inline: 1.5rem;

				span.label {
					color: #707170;
					font-size: 14px;
					font-weight: 400;
					width: fit-content;
				}

				>div {
					width: 130px;
				}
			}
		}
	}

	&.bus_accommodation {
		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;

			.bus-wrapper,
			.travelers-wrapper {
				width: 100% !important;
				max-width: 100% !important;
			}
		}

		&.trip_operations {
			padding: 0;
			border-radius: initial;
			background-color: initial;
		}

		display: grid;
		grid-template-columns: auto 1fr;
		gap: 1rem;

		.bus-wrapper {
			width: 100%;
		}

		.travelers-wrapper {}

		.traveler-bus-number {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 1px solid rgba(221, 220, 220, 1);
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
		}

		.bus-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0.5rem;
			flex-wrap: nowrap;
			overflow: auto;

			&>* {
				min-width: max-content;
			}

			padding-bottom: 0.5rem;

			.bus-tabbar {
				display: flex;
				gap: 0.3rem;
				contain: inline-size;

				.bus-tabbar-item {
					min-width: fit-content;

					>button {
						&.active {
							border-color: #f6ebda;
							background: #faf4eb;
							color: #6a4d1b;

							.bus-outline-icon,
							.plus-icon {
								svg path {
									stroke: rgba(106, 77, 27, 1);
								}
							}
						}

						color: rgba(112, 113, 112, 1);
						border: 1px solid rgba(221, 220, 220, 1);
						background: white;
						font-weight: bold;
						display: flex;
						align-items: center;
						gap: 0.5rem;
						outline: none;
						padding: 0.5rem;
						border-radius: 4px;
					}

					&.add-bus {
						position: sticky;
						right: 0;

						>button {
							gap: 0.2rem;
						}
					}
				}
			}

			.save-btn {
				color: white;
				background-color: #d4a655;
				padding: 0.5rem;
				display: flex;
				gap: 0.5rem;
				border-radius: 4px;
				outline: none;
				min-width: max-content;

				svg path {
					stroke: white;
				}
			}
		}

		.add-bus-form {
			.save-btn {
				color: white;
				background-color: #d4a655;
				padding: 0.5rem;
				border-radius: 4px;
				outline: none;
				min-width: max-content;
				width: 100%;
			}

			div:has(.save-btn) {
				margin-top: 1.85rem;
			}
		}

		.bus-operations {
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			align-items: center;

			@media screen and (min-width: 1114px) {
				button:last-child {
					margin-left: auto;
				}
			}
		}

		.no-bus-wrapper {
			padding: 40px;
			margin-inline: auto;
			border: 2px solid rgba(221, 220, 220, 0.2);
			border-radius: 8px;
			margin-bottom: 1.5rem;
			margin-top: 2rem;
		}

		.info-list {
			row-gap: 1rem;
		}

		.bus-info {
			display: flex;
			flex-direction: column;

			.bus-info-label {
				color: #707170;
				font-size: 14px;
			}
		}

		.seat-draw-wrapper {
			contain: inline-size;
		}

		.bus-seat-wrapper {
			position: relative;

			.unasign-seat-button {
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(45%, -45%);

				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #FAF4EB;
				border: 2px solid #EED9BA;
				border-radius: 6px;
				padding: 2px 4px;
				opacity: 0;
				transition: opacity .2s ease-out, transform .3s ease-out;

				&:hover {
					transform: translate(45%, -45%) scale(1.2);
				}
			}

			&:hover {
				.unasign-seat-button {
					opacity: 1;
				}
			}
		}

		.bus-color-guide {
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;

			.bus-color-guide-item {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				margin-bottom: 0.5rem;

				.bus-color-guide-circle {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					border: 2px solid black;
					position: relative;

					&::after {
						content: "";
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 15px;
						height: 15px;
						border-radius: 50%;
						background-color: #d4a655;
					}

					&.available {
						border-color: black;

						&::after {
							background-color: rgba(247, 246, 246, 1);
						}
					}

					&.selected {
						border-color: rgba(212, 166, 85, 1);

						&::after {
							background-color: rgba(250, 244, 235, 1);
						}
					}

					&.booked {
						border-color: black;

						&::after {
							background-color: rgba(0, 126, 61, 1);
						}
					}

					&.disabled {
						border-color: black;
						background-color: transparent !important;

						&::after {
							background-color: rgba(255, 49, 49, 1);
						}
					}
				}
			}
		}
	}
}

.modal {
	strong {
		font-weight: bolder;

		em {
			font-weight: bolder;
			font-style: italic;
		}
	}

	em {
		font-style: italic;
	}

	.modal-content {
		border-radius: 12px;
		border: none !important;
		outline: none !important;

		.modal-body {
			.content {
				.title {
					margin-top: 20px;
					font-size: 18px;
					color: rgba(16, 24, 40, 1);
					font-weight: 600;
				}

				p {
					line-height: 1.4;
					margin-top: 8px;

					b {
						color: rgba(102, 112, 133, 1);
					}
				}
			}
		}
	}

	.delete-confirmation-modal {
		.actions-btn-group {
			display: flex;
			gap: 12px;
			margin-top: 1rem;

			button {
				background-color: transparent;
				border: none;
				outline: none;
				border-radius: 8px;
				padding: 10px 18px;
				flex: 1;
			}

			.outlined-btn {
				background-color: white;
				border: 1px solid rgba(208, 213, 221, 1);
				color: rgba(52, 64, 84, 1);
			}

			.danger-btn {
				background-color: rgba(234, 84, 85, 1);
				border: 1px solid rgba(217, 45, 32, 1);
				color: white;
			}
		}
	}

	.send-operation-error-modal {
		ul {
			display: flex;
			flex-direction: column;
			gap: 4px;
			margin-top: 8px;

			li {
				display: flex;
				gap: 10px;
				align-items: center;
				list-style-type: none;
				padding-left: 1rem;
			}
		}
	}

	.send-operation-error-modal {
		.modal-title {
			width: 100%;
			text-align: center;
		}
	}

	.send-operation-modal {
		.company-select-row {
			display: flex;
			gap: 1rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding-bottom: 1.2rem;
			margin-bottom: 1.2rem;

			.company-select {
				flex: 1;
			}

			div:has(button) {
				max-width: fit-content;
				margin-top: auto;

				button {
					margin-bottom: 0.25rem;
					height: 44px;
				}
			}
		}

		.generated-link-row {
			display: flex;
			gap: 1rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding-bottom: 1.2rem;
			margin-bottom: 1.2rem;
			align-items: center;

			>span {
				color: #707170;
			}

			.generated-link {
				background-color: #f7f6f6;
				color: #dddcdc;
				border: 1px solid #dddcdc;
				padding: 0.7rem;
				border-radius: 6px;
				position: relative;
				overflow: hidden;
				flex: 1;

				.copy-btn {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 104px;
					border-top-right-radius: 6px;
					border-bottom-right-radius: 6px;

					svg path {
						stroke: white;
					}
				}
			}
		}

		.send-by {
			p {
				margin-bottom: 24px;
				color: rgba(119, 119, 119, 1);

				b {
					color: black;
				}
			}

			.fields {
				display: flex;
				gap: 1rem;
				flex-wrap: wrap;

				>div {
					min-width: 200px;
					flex: 1;
				}

				.phone {
					min-width: 300px;
					flex: 1;

					@media screen and (max-width: 768px) {
						min-width: 100%;
					}

					.phone_field_group {
						display: flex;
						flex-wrap: nowrap;
						gap: 0.5rem;

						.flag {
							min-width: 80px;
						}

						.no-flag {
							min-width: 120px;
						}

						.phone_number {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.add-room-modal {
		.selected-hotels {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 1rem;
			margin-block: 1rem;

			>span {
				font-size: 18px;
				font-weight: 600;
				color: black;
			}

			.hotels-list {
				display: flex;
				gap: 0.5rem;

				.hotels-list-item {
					button {
						outline: none;
						border: none;
						background-color: transparent;
						display: flex;
						gap: 0.6rem;
						padding: 6px 14px;
						border-radius: 4px;
						color: #707170;

						svg path {
							stroke: #707170;
						}
					}
				}

				.hotels-list-item.active {
					button {
						border: 1px solid #f6ebda;
						background-color: #faf4eb;
						color: #6a4d1b;

						svg path {
							stroke: #6a4d1b;
						}
					}
				}
			}
		}

		.room-count {
			width: 100%;
			background-color: #dddcdc;
			color: black;
			font-size: 18px;
			font-weight: 600;
			text-align: center;
			border-radius: 4px;
			padding: 12px;
		}

		.rooms-list {
			gap: 1rem;
			margin-top: 1rem;

			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

			li {
				.room-type {
					background-color: #f7f6f6;
					border: 1px solid #7071701a;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 4px;

					div {
						border: 2px solid #f6ebda;
						background-color: #faf4eb;
						border-radius: 24px;
						padding: 4px 10px;
						color: #d4a655;

						svg path {
							stroke: #d4a655;
						}
					}
				}

				.count {
					.control-field__body {
						padding: 0;
					}

					div:has(input),
					input {
						height: 100%;
					}

					input {
						text-align: center;
						padding: 8px 0;
					}
				}
			}
		}

		.add-rooms-btn {
			border: none;
			outline: none;
			background-color: #d4a655;
			color: white;
			text-align: center;
			border-radius: 8px;
			width: 100%;
			padding: 16px;
			margin-top: 1rem;
			font-weight: 700;
		}
	}
}