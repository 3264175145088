.b2c_hajj {
	.card {
		background-color: white;
		padding: 24px;
		border-radius: 8px;
		border: none;

		.title {
			color: #050001;
			font-size: 24px;
			font-weight: 700;
		}

		.divider {
			background-color: #f7f6f6;
			height: 1px;
			margin-block: 20px;
			margin-inline: 12px;
		}
	}

	&.setup {
		.card.setup {
			.content {
				.title {
					color: #707170;
					font-weight: 400;
					margin-bottom: 20px;
					font-size: 16px;
				}

				.checkboxes {
					display: flex;
					flex-wrap: wrap;
					gap: 10px;

					.checkbox-container {
						position: relative;
						display: inline-flex;
						align-items: center;
						gap: 8px;
						cursor: pointer;
						user-select: none;
						filter: unset;

						.checkbox {
							position: relative;
							width: 20px;
							height: 20px;
							border: 2px solid #ccc;
							border-radius: 4px;
							background-color: white;
							transition: all 200ms ease-in-out;

							.checkmark {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 12px;
								height: 12px;
								stroke: white;
								stroke-width: 2;
								opacity: 0;
								transition: opacity 200ms ease-in-out;
							}
						}

						.checkbox-input {
							position: absolute;
							opacity: 0;
							width: 0;
							height: 0;

							&:checked ~ .checkbox {
								background-color: #2196f3;
								border-color: #2196f3;

								.checkmark {
									opacity: 1;
								}
							}

							&:focus ~ .checkbox {
								box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.3);
							}

							&:focus:not(:focus-visible) ~ .checkbox {
								box-shadow: none;
							}
						}

						.checkbox-label {
							font-size: 16px;
							color: #333;
							margin-left: 4px;
						}

						&:hover {
							.checkbox {
								border-color: #2196f3;
							}
						}
					}
				}

				.generate-btn {
					background-color: #d4a655;
					border-radius: 4px;
					padding: 12px 20px;
					font-weight: 700;
					margin-top: 30px;
					border: none;
					color: #fefeff;
				}
			}
		}
	}

	&.requests {
		.filters {
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;

			> div {
				gap: 1rem;
				min-width: 200px;
				width: 0;

				@media screen and (max-width: 768px) {
					width: 100%;
				}
			}

			> div:has(.reset-btn) {
				align-self: flex-end;
				margin-bottom: 0.25rem;
			}
		}

		.table-container {
			overflow-x: auto;
			margin-block: 20px;

			table {
				width: 100%;
				min-width: max-content;
				border-collapse: separate;
				border-spacing: 0;
				font-size: 14px;
				color: #333;

				th,
				td {
					padding: 10px 15px;
				}

				th {
					border-top: 1px solid #dbdade;
					border-bottom: 1px solid #dbdade;
					background-color: #f7f6f6;
					color: #707170;
					font-size: 14px;
					font-weight: 700;
				}

				th:first-child {
					border-left: 1px solid #dbdade;
					border-top-left-radius: 8px;
				}

				th:last-child {
					border-right: 1px solid #dbdade;
					border-top-right-radius: 8px;
				}

				td {
					border-bottom: 1px solid #dbdade;
				}

				thead tr td:first-child {
					border-left: 1px solid #dbdade;
				}

				tbody tr td:first-child {
					border-left: 1px solid #dbdade;
				}

				tbody tr td:last-child {
					border-right: 1px solid #dbdade;
				}

				tbody tr:last-child td:first-child {
					border-bottom-left-radius: 8px;
				}

				tbody tr:last-child td:last-child {
					border-bottom-right-radius: 8px;
				}

				.action-button {
					border: none;
					outline: none;
					background-color: transparent;
				}
			}
		}
	}

	&.view {
		background-color: white;
		padding: 24px;
		border-radius: 8px;
		border: none;
		display: flex;
		gap: 1.5rem;
		@media screen and (max-width: 768px) {
			flex-direction: column;
		}

		.image-cards {
			display: flex;
			gap: 1rem;
			flex-direction: column;
			flex-grow: 0;
			flex-shrink: 0;
			justify-content: center;

			@media screen and (max-width: 768px) {
				flex-direction: row;
				flex-wrap: wrap;
			}

			.image-card {
				background-color: #f9f9f9; // show it while image is loading
				border-radius: 8px;
				img {
					width: 280px;
					height: 200px;
					border-radius: 8px;
					overflow: hidden;
					object-fit: cover;
				}
			}
		}

		.info {
			padding: 0;
			width: auto;
			flex-grow: 1;

			.info-cards {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.info-card {
					background-color: #f9f9f9;
					padding: 20px;

					h2 {
						margin-bottom: 30px;
						color: black;
						font-weight: 700;
						font-size: 20px;
					}

					.info-card-body {
						display: flex;
						flex-wrap: wrap;
						row-gap: 2rem;
						column-gap: 1.5rem;
					}

					.info-card-item {
						display: flex;
						gap: 1rem;

						.info-card-label {
							color: #707170;
						}

						.info-card-value {
							color: black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	.export-execl-btn {
		outline: none;
		border: 2px solid rgba(33, 150, 83, 1);
		color: rgba(33, 150, 83, 1);
		font-weight: bold;
		background-color: transparent;
		padding: 10px 26px 10px 20px;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		min-width: fit-content;
	}

	.primary-button {
		background-color: #d4a655;
		color: white;
		border: none;
		font-weight: bold;
		padding: 10px 20px;
		border-radius: 5px;
		cursor: pointer;

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		min-width: fit-content;
	}

	.reset-btn {
		min-width: fit-content;

		color: rgba(106, 77, 27, 1);
		svg path {
			stroke: rgba(106, 77, 27, 1);
		}

		background-color: rgba(250, 244, 235, 1);
		border: 1px solid rgba(238, 217, 186, 1);
	}
}

.generate-link-modal-content {
	display: flex;
	align-items: center;
	gap: 1rem;

	.copy-link {
		display: flex;
		align-items: center;
		background-color: #f7f6f6;
		border-radius: 4px;
		padding: 8px 12px;
		flex: 1;
		width: 100%;
		min-width: 0;
		font-weight: 700;
		border: none;
		color: #fefeff;
		position: relative;
		border: 1px solid #dddcdc;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: #dddcdc;
		}

		button {
			position: absolute;
			top: 0;
			right: 0;
			width: fit-content;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			span {
				color: white;
			}

			svg path {
				stroke: white;
			}
		}
	}
}
